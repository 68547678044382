<script>
import DefaultMixin from '../mixin'

export default {
  name: 'SetupLayout',
  provide: function () {
    return {
      page: this
    }
  },
  mixins: [DefaultMixin],
  components: {},
  data() {
    return {
    }
  },
  computed: {},
  methods: {
  },
  meta: {
    title: 'Instalador SL',
    name: 'Instalador SL'
  }
}
</script>

<template>
  <div class="flex col-grow no-wrap h-full column">
    <router-view/>
  </div>
</template>
